.footer{
    color: white;
    padding:7vh;
    background-color: #383736;
    padding-top: 12vh;
    }
    
    .mobile{
        flex-direction: column;
        background-color: #383736;
        color: white;
        padding-top: 8vh;
        padding-left: 4vh;
        padding-right: 4vh;
        padding-bottom: 30px;
    }



    .menu{
        border-left:2px solid #c2b19c;
        padding-left:10px;
        width:150px;
        font-size: 14px;
        cursor: pointer;
        text-decoration: none;
        color:white;
    }

    .menu.foot{
            margin-top: 30px;
            font-size: 16px;
       
    }

    .menu:hover{
        
          color: #c2b19c;
          transition: 1000ms;
        
        }

    .icon{
        color: #c2b19c;
        margin-left: 20px;
        height: 20px;
        width: 20px;
    }

    .main{
        display: flex;
        flex-direction: row;
        margin-top: 15vh;
        justify-content: space-between;
    }
    
    .link{
  
        padding-bottom: 8px;
        font-size: 15px;
        color: white;
        transition: 100ms;
    }
    
    .link:hover{
    transition: 100ms;
      font-size: 20px;
      color: white;
    
    }
    a:hover{
        text-decoration: none;
    }
    

    .foot{
        display:flex;
        flex-direction: column-reverse;
    }
 