.container {
  font-family: Arial, sans-serif;
}

.header {

  padding: 10px;
}

.brand {
  font-size: 24px;
  font-weight: bold;
}

.navbar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-menu li {
  display: inline-block;
  margin-right: 10px;
}

.navbar-menu li a {
  text-decoration: none;
  color: #333;
}

.hero {

  padding: 50px 0;
  text-align: center;
}

.hero-title {
  font-size: 32px;
  font-weight: bold;
}

.hero-description {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.hero-button {
  display: inline-block;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
}

.features {
  padding: 50px 0;
  text-align: center;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}

.feature {
  margin-bottom: 20px;
}

.feature-title {
  font-size: 20px;
  font-weight: bold;
}

.feature-description {
  color: #666;
}

.project {
  overflow-y: auto;
 
  overscroll-behavior-y:contain;
  scroll-snap-type: y mandatory;
  height: 100vh;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.section{
  height: 100vh;
  width: 100%;
  scroll-snap-align: center;

}

.endtext{
padding-top: 25px;
border-bottom: 1px solid black;
cursor: pointer;
font-size: 14px;
color: black;
text-decoration: none;
}


.endtext:hover {


  transition: 1s;
  color: brown;
  border-bottom: 1px solid brown;

}