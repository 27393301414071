
    .about {
  
        position:static;
        height: 100vh;
        background-size:cover;
        background-position:center;
        background-image:url('../Assets/d1.jpg');
        display: flex;
      align-items: center;
 
     
  
   
      }

    
